// These questions are asked when a user joins the site, and the answers may be updated via 'settings'
// They are different from standard survey questions because the responses are stored on the user model,
// rather than in a 'response' in the database. Some 'core' questions are required (and this is validated)
// on the admin side where the question order is set in the portal's admin.

const aQs = { 
    // ------------------------------------ order
    coreOrder() {
        return ["name", "location", "birthday", "ethnicity", "sex", "medical"];  
    },
    // ------------------------------------ core questions
    name(org_q_set) {
        return [
            {
                surveyId: "useraccount",
                width: "narrower",
                visibleIf: [],
                id: "useraccount-000001",
                name: "Name",
                locked: true,
                elements: [
                    {
                        id: "useraccount-00001-00001",
                        title: "What should we call you?",
                        subtitle: org_q_set === "GE-qs" ? "" : "We will use this when we contact you.",
                        name: "name",
                        type: "text",
                        isRequired: true,
                        placeholder: "Preferred name",
                    },
                    {
                        id: "useraccount-00001-00002",
                        title: "What's your full name?",
                        subtitle: org_q_set === "GE-qs" ? "" : "Many of our studies include the opportunity to receive a free at-home Sano DNA Test, with some studies also including the option for us to retrieve your Electronic Health Records on your behalf. It’s therefore important for us to know your full name as it would appear on your passport or driving license. We will never share this information without your explicit consent and we only ask for what we absolutely need.",
                        type: "text",
                        name: "fullname",
                        isRequired: true,
                        placeholder: "Full name",
                    },
                ],
            },
        ];
    },
    location(org_q_set) {
        return [
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    visibleIf: [],
                    id: "useraccount-00003",
                    name: "Location",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00003-00001",
                            title: org_q_set === "GE-qs" ? "Which city do you live in or near to?" : "Which city do you live in?",
                            name: "location",
                            type: "dropdown",
                            isRequired: true,
                            selectMulti: false,
                            api: "maps",
                            explanation:
                                org_q_set === "GE-qs"
                                ? "We're not after your full address! We would just like to know a general location so that we can understand where approximately survey participants are in England."
                                : "We're not after your full address! We just need to know a general location so that we can match you with research projects taking place relatively close by.",
                        },
                    ],
                },
            ];
    },
    birthday(org_q_set) {
        return [
            {
                surveyId: "useraccount",
                visibleIf: [{ question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true }],
                width: "narrower",
                id: "useraccount-00002",
                name: "Date of birth",
                locked: true,
                elements: [
                    {
                        id: "useraccount-00002-00001",
                        title: "What is your date of birth?",
                        name: "birthday",
                        type: "datepicker",
                        isRequired: true,
                        valid_date_range_vs_today: "less_than_or_equal",
                        explanation:
                            org_q_set === "GE-qs"
                            ? "This is useful to know when evaluating your responses to questions in other points in the survey."
                            : "Date of birth is useful for us to know because certain studies might require participants to be in a certain age range, for example over 18.",
                    },
                ],
            },
            // US version - exactly the same, but inputs arranged like MM/DD/YYYY
            {
                surveyId: "useraccount",
                visibleIf: [{ question: "useraccount-00003-00001", questionName: "location", comparison: "mustContain", answer: " ?USA$", regex: true }],
                width: "narrower",
                id: "useraccount-00002b",
                name: "Date of birth US",
                locked: true,
                elements: [
                    {
                        id: "useraccount-00002b-00001",
                        title: "What is your date of birth?",
                        name: "birthday_us_format",
                        type: "datepicker",
                        isRequired: true,
                        monthFirstFormat: true,
                        valid_date_range_vs_today: "less_than_or_equal",
                        explanation:
                            org_q_set === "GE-qs"
                            ? "This is useful to know when evaluating your responses to questions in other points in the survey."
                            : "Date of birth is useful for us to know because certain studies might require participants to be in a certain age range, for example over 18.",
                    },
                ],
            },
        ];
    },
    sex(org_q_set) {
        return [
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    visibleIf: [],
                    id: "useraccount-00004",
                    name: "Sex",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00004-00001",
                            title: "What sex were you assigned at birth?",
                            name: "sex",
                            type: "multiple-choice",
                            objectChoices: true,
                            isRequired: true,
                            choices: [
                                { value: "female", text: "Female" },
                                { value: "intersex", text: "Intersex" },
                                { value: "male", text: "Male" },
                                { value: "prefer not to say", text: "Prefer not to say" },
                            ],
                            explanation:
                                org_q_set === "GE-qs"
                                ? "Please note that we don't assume that this matches anyone's gender."
                                : "We're asking about sex as assigned at birth because chromosome differences may have an impact in certain studies.We don't assume that this matches anyone's gender, and we don't ask about gender unless it is necessary for a certain study, so that we're not holding more data than we really need.",
                        },
                    ],
                },
            ];
    },
    ethnicity() {
        return [
                // UK question set [default]
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} notcontains ' ?USA$'",
                    visibleIf: [{ question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true }],
                    id: "useraccount-00005",
                    name: "Ethnicity [UK]",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00005-00001",
                            title: "What is your ethnic group?",
                            name: "ethnicity_broad_uk",
                            type: "multiple-choice",
                            choices: [
                                { value: "Asian or Asian British", text: "Asian or Asian British" },
                                { value: "Black, African, Black British or Caribbean", text: "Black, African, Black British or Caribbean" },
                                { value: "Mixed or multiple ethnic groups", text: "Mixed or multiple ethnic groups" },
                                { value: "White", text: "White" },
                                { value: "Another ethnic group", text: "Another ethnic group" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                            isRequired: true,
                            explanation:
                                "We ask about ethnicity to allow researchers to identify relationships between ethnicity and other factors such as disease symptoms or prevalence. This information also helps us ensure that research run on this platform is as representative as possible.",
                        },
                    ],
                },
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} notcontains ' ?USA$' [and] {ethnicity_broad_uk} = 'Asian or Asian British'",
                    visibleIf: [
                        { question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true },
                        { question: "useraccount-00005-00001", questionName: "ethnicity_broad_uk", comparison: "mustEqual", answer: "Asian or Asian British" }
                    ],
                    id: "useraccount-00006",
                    name: "Ethnicity [UK] Asian or Asian British",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00006-00001",
                            title: "Which one best describes your Asian or Asian British background?",
                            name: "ethnicity_narrow_uk_asian_asian_british",
                            type: "multiple-choice",
                            choices: [
                                { value: "Bangladeshi", text: "Bangladeshi" },
                                { value: "Chinese", text: "Chinese" },
                                { value: "Indian", text: "Indian" },
                                { value: "Pakistani", text: "Pakistani" },
                                { value: "Another Asian background", text: "Another Asian background" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                        },
                    ],
                },
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} notcontains ' ?USA$' [and] {ethnicity_broad_uk} = 'Black, African, Black British or Caribbean'",
                    visibleIf: [
                        { question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true },
                        { question: "useraccount-00005-00001", questionName: "ethnicity_broad_uk", comparison: "mustEqual", answer: "Black, African, Black British or Caribbean" }
                    ],
                    id: "useraccount-00007",
                    name: "Ethnicity [UK] Black, African, Black British or Caribbean",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00007-00001",
                            title: "Which one best describes your Black, African, Black British or Caribbean background?",
                            name: "ethnicity_narrow_uk_black_african_black_bri_cari",
                            type: "multiple-choice",
                            choices: [
                                { value: "African", text: "African" },
                                { value: "Caribbean", text: "Caribbean" },
                                { value: "Another Black background", text: "Another Black background" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                        },
                    ],
                },
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} notcontains ' ?USA$' [and] {ethnicity_broad_uk} = 'Mixed or multiple ethnic groups'",
                    visibleIf: [
                        { question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true },
                        { question: "useraccount-00005-00001", questionName: "ethnicity_broad_uk", comparison: "mustEqual", answer: "Mixed or multiple ethnic groups" }
                    ],
                    id: "useraccount-00008",
                    name: "Ethnicity [UK] Mixed or multiple ethnic groups",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00008-00001",
                            title: "Which one best describes your Mixed or Multiple ethnic groups background?",
                            name: "ethnicity_narrow_uk_mixed_multiple_ethnic_groups",
                            type: "multiple-choice",
                            choices: [
                                { value: "Asian and White", text: "Asian and White" },
                                { value: "Black African and White", text: "Black African and White" },
                                { value: "Black Caribbean and White", text: "Black Caribbean and White" },
                                { value: "Another Mixed background", text: "Another Mixed background" },
                                { value: "Prefer not to say", text: "Prefer not to say" }
                            ],
                        },
                    ],
                },
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} notcontains ' ?USA$' [and] {ethnicity_broad_uk} = 'White'",
                    visibleIf: [
                        { question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true },
                        { question: "useraccount-00005-00001", questionName: "ethnicity_broad_uk", comparison: "mustEqual", answer: "White" }
                    ],
                    id: "useraccount-00009",
                    name: "Ethnicity [UK] White",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00009-00001",
                            title: "Which one best describes your White background?",
                            name: "ethnicity_narrow_uk_white",
                            type: "multiple-choice",
                            choices: [
                                { value: "British, English, Northern Irish, Scottish, or Welsh", text: "British, English, Northern Irish, Scottish, or Welsh" },
                                { value: "Irish", text: "Irish" },
                                { value: "Irish Traveller or Gypsy", text: "Irish Traveller or Gypsy" },
                                { value: "Another White background", text: "Another White background" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                        },
                    ],
                },
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} notcontains ' ?USA$' [and] {ethnicity_broad_uk} = 'Another ethnic group'",
                    visibleIf: [
                        { question: "useraccount-00003-00001", questionName: "location", comparison: "mustNotContain", answer: " ?USA$", regex: true },
                        { question: "useraccount-00005-00001", questionName: "ethnicity_broad_uk", comparison: "mustEqual", answer: "Another ethnic group" }
                    ],
                    id: "useraccount-00010",
                    name: "Ethnicity [UK] Another ethnic group",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00010-00001",
                            title: "Which one best describes your background?",
                            name: "ethnicity_narrow_uk_another",
                            type: "multiple-choice",
                            choices: [
                                { value: "Arab", text: "Arab" },
                                { value: "Another ethnic background", text: "Another ethnic background" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                        },
                        {
                            id: "useraccount-00010-00002",
                            subtitle: "If we haven't included an option that is right for you, please answer here if you would like to:",
                            name: "ethnicity_another_custom_text_uk",
                            type: "text",
                        },
                    ],
                },
                // US question set
                {
                    surveyId: "useraccount",
                    width: "narrower",
                    // visibleIf: "{location} contains ' ?USA$'",
                    visibleIf: [{ question: "useraccount-00003-00001", questionName: "location", comparison: "mustContain", answer: " ?USA$", regex: true }],
                    id: "useraccount-00011",
                    name: "Ethnicity [USA]",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00011-00001",
                            title: "Are you Hispanic, Latino/a, or of Spanish origin?",
                            subtitle: "(One or more categories may be selected)",
                            name: "ethnicity_us",
                            type: "multiple-choice",
                            selectMulti: true,
                            isRequired: true,
                            choices: [
                                { value: "No, not of Hispanic, Latino/a, or Spanish origin", text: "No, not of Hispanic, Latino/a, or Spanish origin" },
                                { value: "Yes, Mexican, Mexican American, Chicano/a", text: "Yes, Mexican, Mexican American, Chicano/a" },
                                { value: "Yes, Puerto Rican", text: "Yes, Puerto Rican" },
                                { value: "Yes, Cuban", text: "Yes, Cuban" },
                                { value: "Yes, Another Hispanic, Latino/a or Spanish origin", text: "Yes, Another Hispanic, Latino/a or Spanish origin" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                            explanation:
                                "We ask about ethnicity to allow researchers to identify relationships between ethnicity and other factors such as disease symptoms or prevalence. This information also helps us ensure that research run on this platform is as representative as possible. (As your location appears to be in the US, we are using the US FDA data standard options.)",
                        },
                    ],
                },
                {
                    surveyId: "useraccount",
                    // visibleIf: "{location} contains ' ?USA$'",
                    visibleIf: [{ question: "useraccount-00003-00001", questionName: "location", comparison: "mustContain", answer: " ?USA$", regex: true }],
                    id: "useraccount-00012",
                    name: "Race [USA]",
                    locked: true,
                    elements: [
                        {
                            id: "useraccount-00012-00001",
                            title: "What is your race?",
                            subtitle: "(One or more categories may be selected)",
                            name: "race_us",
                            type: "multiple-choice",
                            selectMulti: true,
                            isRequired: true,
                            choices: [
                                { value: "White", text: "White" },
                                { value: "Black or African American", text: "Black or African American" },
                                { value: "American Indian or Alaska Native", text: "American Indian or Alaska Native" },
                                { value: "Asian Indian", text: "Asian Indian" },
                                { value: "Chinese", text: "Chinese" },
                                { value: "Filipino", text: "Filipino" },
                                { value: "Japanese", text: "Japanese" },
                                { value: "Korean", text: "Korean" },
                                { value: "Vietnamese", text: "Vietnamese" },
                                { value: "Other Asian", text: "Other Asian" },
                                { value: "Native Hawaiian", text: "Native Hawaiian" },
                                { value: "Guamanian or Chamorro", text: "Guamanian or Chamorro" },
                                { value: "Samoan", text: "Samoan" },
                                { value: "Other Pacific Islander", text: "Other Pacific Islander" },
                                { value: "Prefer not to say", text: "Prefer not to say" },
                            ],
                        },
                    ],
                },
            ];
    },
    // ------------------------------------ medical history questions
    medical(org_q_set) {
        return [
            {
                surveyId: "useraccount",
                id: "useraccount-00013",
                name: "Medical history",
                visibleIf: [],
                locked: true,
                elements: [{
                    id: "useraccount-00013-00001",
                    name: "med_categories",
                    title: "Medical History",
                    subtitle:
                        org_q_set === "GE-qs"
                            ? "You may have already supplied this information, but it might have been a while ago. You do not have to complete this section if you feel this is up to date or if you would prefer not to. In your lifetime, have you ever been diagnosed with any of the following types of conditions? Click all that apply."
                        : "In your lifetime, have you ever been diagnosed with any of the following types of conditions? Click all that apply.",
                    type: "multiple-choice",
                    selectMulti: true,
                    objectChoices: true,
                    choices: [
                        {
                            text: "Blood related",
                            value: "med_blood"
                        }, 
                        {
                            text: "Bone related",
                            value: "med_bone"
                        }, 
                        {
                            text: "Cancers",
                            value: "med_cancer"
                        }, 
                        {
                            text: "Cardio-vascular",
                            value: "med_cardiovascular"
                        }, 
                        {
                            text: "Digestive system",
                            value: "med_digestive"
                        }, 
                        {
                            text: "Endocrine, nutritional or metabolic",
                            value: "med_metabolic_endocrine"
                        }, 
                        {
                            text: "Immune system",
                            value: "med_immune"
                        }, 
                        {
                            text: "Liver, Pancreas, Spleen & Kidney",
                            value: "med_liver_pancreas_spleen_kidney"
                        }, 
                        {
                            text: "Mental health",
                            value: "med_mh"
                        }, 
                        {
                            text: "Nervous system & brain",
                            value: "med_neurological"
                        }, 
                        {
                            text: "Pregnancy complications",
                            value: "med_pregnancy_complications"
                        }, 
                        {
                            text: "Rare conditions",
                            value: "med_rare"
                        }, 
                        {
                            text: "Lung & Respiratory system",
                            value: "med_respiratory"
                        }, 
                        {
                            text: "Senses",
                            value: "med_sense"
                        }, 
                        {
                            text: "Skin related",
                            value: "med_skin"
                        }, 
                        {
                            text: "Sexual / Reproductive health",
                            value: "med_srh"
                        }
                    ],
                }]
            },
            {
                surveyId: "useraccount",
                id: "useraccount-00014",
                name: "Blood conditions",
                // visibleIf: "{med_categories} contains 'med_blood'"
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_blood" }],
                locked: true,
                elements: [{
                    id: "useraccount-00014-00001",
                    name: "med_blood",
                    title: "Blood conditions",
                    subtitle: "Please let us know which of these you have been diagnosed with.",
                    type: "multiple-choice",
                    selectMulti: true,
                    objectChoices: true,
                    choices: aQs.conditionsByCategory.med_blood,
                 }]
            },
            {
                surveyId: "useraccount",
                id: "useraccount-00015",
                name: "Bone conditions",
                // visibleIf: "{med_categories} contains 'med_bone'",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_bone" }],
                locked: true,
                elements: [
                    {
                        id: "useraccount-00015-00001",
                        name: "med_bone",
                        title: "Bone conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_bone,
                    }
                ]
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00016",
                name: "Cancer types",
                // visibleIf: "{med_categories} contains 'med_cancer'",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_cancer" }],
                locked: true,
                elements: [
                    {
                        id: "useraccount-00016-00001",
                        name: "med_cancer",
                        title: "Cancer types",
                        subtitle: "Please let us know which of these you have been diagnosed with, and/or if a type of cancer you have is not listed.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_cancer,
                }],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00017",
                name: "Cardiovascular conditions",
                // "visibleIf": "{med_categories} contains 'med_cardiovascular'"
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_cardiovascular" }],
                locked: true,
                elements: [
                    {
                        id: "useraccount-00017-00001",
                        name: "med_cardiovascular",
                        title: "Cardiovascular conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_cardiovascular,
                    }
                ],
            }, {
                surveyId: "useraccount",
                id: "useraccount-00018",
                name: "Digestive conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_digestive" }],
                // "visibleIf": "{med_categories} contains 'med_digestive'",
                locked: true,
                elements: [
                    {
                        id: "useraccount-00018-00001",
                        name: "med_digestive",
                        title: "Digestive conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_digestive,
                    }
                ]
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00019",
                name: "Endocrine, nutritional & metabolic conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_metabolic_endocrine" }],
                // "visibleIf": "{med_categories} contains 'med_metabolic_endocrine'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00019-00001",
                        name: "med_metabolic_endocrine",
                        title: "Endocrine, nutritional & metabolic conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_metabolic_endocrine,
                    }
                ],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00020",
                name: "Immune system conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_immune" }],
                // "visibleIf": "{med_categories} contains 'med_immune'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00020-00001",
                        name: "med_immune",
                        title: "Immune system conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.</span>",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_immune,
                    }
                ]
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00021",
                name: "Liver, pancreas, spleen & kidney conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_liver_pancreas_spleen_kidney" }],
                // "visibleIf": "{med_categories} contains 'med_liver_pancreas_spleen_kidney'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00021-00001",
                        name: "med_liver_pancreas_spleen_kidney",
                        title: "Liver, pancreas, spleen & kidney conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_liver_pancreas_spleen_kidney,
                    }
                ],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00022",
                name: "Mental health conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_mh" }],
                // "visibleIf": "{med_categories} contains 'med_mh'",
                locked: true,
                "elements": [
                    {
                        id: "useraccount-00022-00001",
                        name: "med_mh",
                        title: "Mental health conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_mh,
                    }
                ],
           }, 
           {
                surveyId: "useraccount",
                id: "useraccount-00023",
                name: "Nervous system & brain conditions",
               visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_neurological" }],
                // "visibleIf": "{med_categories} contains 'med_neurological'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00023-00001",
                        name: "med_neurological",
                        title: "Nervous system & brain conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_neurological,
                    }
                ],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00024",
                name: "Pregnancy complications",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_pregnancy_complications" }],
                // "visibleIf": "{med_categories} contains 'med_pregnancy_complications'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00024-00001",
                        name: "med_pregnancy_complications",
                        title: "Pregnancy complications",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_pregnancy_complications,
                    }
                ],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00025",
                name: "Rare conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_rare" }],
                // "visibleIf": "{med_categories} contains 'med_rare'",
                width: "narrower",
                locked: true,
                elements: [{
                    id: "useraccount-00025-00001",
                    name: "med_rare",
                    api: "raredisease",
                    selectMulti: true,
                    title: "Rare conditions",
                    subtitle: "Please let us know which rare condition[s] you have been diagnosed with. (Begin typing to see options.)",
                    type: "dropdown",
                }],
                
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00026",
                name: "Respiratory conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_respiratory" }],
                // "visibleIf": "{med_categories} contains 'med_respiratory'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00026-00001",
                        name: "med_respiratory",
                        title: "Respiratory conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_respiratory,
                    }
                ],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00027",
                name: "Sense affecting conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_sense" }],
                // "visibleIf": "{med_categories} contains 'med_sense'"
                locked: true,
                elements: [
                    {
                        id: "useraccount-00027-00001",
                        name: "med_sense",
                        title: "Sense affecting conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_sense,
                    }
                ],
            }, 
            {
                surveyId: "useraccount",
                id: "useraccount-00028",
                name: "Skin conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_skin" }],
                // "visibleIf": "{med_categories} contains 'med_skin'"
                locked: true,
                "elements": [
                    {
                        id: "useraccount-00028-00001",
                        name: "med_skin",
                        title: "Skin conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_skin,
                    }
                ],
            },
            {
                surveyId: "useraccount",
                id: "useraccount-00029",
                name: "Sexual / reproductive health conditions",
                visibleIf: [{ question: "useraccount-00013-00001", questionName: "med_categories", comparison: "mustContain", answer: "med_srh" }],
                // "visibleIf": "{med_categories} contains 'med_srh'",
                locked: true,
                "elements": [
                    {
                        id: "useraccount-00029-00001",
                        name: "med_srh",
                        title: "Sexual / reproductive health conditions",
                        subtitle: "Please let us know which of these you have been diagnosed with.",
                        type: "multiple-choice",
                        selectMulti: true,
                        objectChoices: true,
                        choices: aQs.conditionsByCategory.med_srh,
                    }
                ],
            }
        ]
    },
    // ------------------------------------ medical conditions list
    // This is the 'source of truth' list for conditions & categories (exc. rare)
    // which we ask about in the welcome flow
    conditionsByCategory: {
        med_blood: [
            { text: "Anaemias", value: "anaemias" },
            { text: "Coagulation defects", value: "coagulation-defects" },
            { text: "Fibrinolytic defects", value: "fibrinolytic-defects" },
            { text: "Non-thrombocytopenic purpura", value: "non-thrombocytopenic-purpura" },
            { text: "Qualitative platelet defects", value: "qualitative-platelet-defects" },
            { text: "Sickle-cell anemia", value: "sickle-cell-anemia" },
            { text: "Thalassaemia", value: "thalassaemia" },
            { text: "Thrombocytopenia", value: "thrombocytopenia" },
            { text: "Thrombocytosis", value: "thrombocytosis" },
            { text: "Thrombophilia", value: "thrombophilia" },
            // { text: "A blood condition which is not in this list", value: "not-in-list" }
        ],
        med_bone: [
            { text: "Carpal tunnel syndrome", value: "carpal-tunnel-syndrome" },
            { text: "Craniosynostosis", value: "craniosynostosis" },
            { text: "Fracture", value: "fracture" },
            { text: "Gout", value: "gout" },
            { text: "Osteoarthritis", value: "osteoarthritis" },
            { text: "Osteogenesis Imperfecta", value: "osteogenesis-imperfecta" },
            { text: "Osteomyelitis", value: "osteomyelitis" },
            { text: "Osteoporosis", value: "osteoporosis" },
            { text: "Paget\u2019s disease", value: "pagets-disease" },
            { text: "Polymyalgia rheumatica", value: "polymyalgia-rheumatica" },
            { text: "Rheumatoid arthritis", value: "rheumatoid-arthritis" },
            { text: "Rickets", value: "rickets" },
            { text: "Scoliosis", value: "scoliosis" },
            { text: "Spondylosis", value: "spondylosis" },
            // { text: "A bone related condition which is not in this list", value: "not-in-list" }
        ],
        med_cancer: [
            { text: "Adenoid Cystic Carcinoma", value: "adenoid-cystic-carcinoma" },
            { text: "Adrenal Gland Cancer", value: "adrenal-gland-cancer" }, 
            { text: "Amyloidosis", value: "amyloidosis" }, 
            { text: "Anal Cancer", value: "anal-cancer" }, 
            { text: "Basal Cell Carcinoma", value: "basal-cell-carcinoma" }, 
            { text: "Bile Duct Cancer", value: "bile-duct-cancer" }, 
            { text: "Birt Hogg Dube Syndrome", value: "birt-hogg-dube-syndrome" }, 
            { text: "Bladder Cancer", value: "bladder-cancer" }, 
            { text: "Bone Cancer", value: "bone-cancer" }, 
            { text: "Brain Tumor", value: "brain-tumor" }, 
            { text: "Breast Cancer", value: "breast-cancer" }, 
            { text: "Carcinoid Tumor", value: "carcinoid-tumor" }, 
            { text: "Cervical Cancer", value: "cervical-cancer" }, 
            { text: "Colorectal Cancer", value: "colorectal-cancer" }, 
            { text: "Ductal Carcinoma", value: "ductal-carcinoma" }, 
            { text: "Endometrial Cancer", value: "endometrial-cancer" }, 
            { text: "Esophageal Cancer", value: "esophageal-cancer"}, 
            { text: "Gastric Cancer", value: "gastric-cancer" }, 
            { text: "Islet Cell Tumor", value: "islet-cell-tumor" }, 
            { text: "Juvenile Polyposis Syndrome", value: "juvenile-polyposis-syndrome" }, 
            { text: "Kidney Cancer", value: "kidney-cancer" }, 
            { text: "Laryngeal Cancer", value: "laryngeal-cancer" }, 
            { text: "Leukemia - Acute Lymphoblastic", value: "acute-lymphoblastic-leukemia" }, 
            { text: "Leukemia - Acute Lymphocytic", value: "acute-lymphocytic-leukemia" }, 
            { text: "Leukemia - Acute Myeloid", value: "acute-myeloid-leukemia" }, 
            { text: "Leukemia - Chronic Lymphocytic", value: "chronic-lymphocytic-leukemia" }, 
            { text: "Leukemia - Chronic Myeloid", value: "chronic-myeloid-leukemia" }, 
            { text: "Liver Cancer", value: "liver-cancer" }, 
            { text: "Lobular Carcinoma", value: "lobular-carcinoma" }, 
            { text: "Lung Cancer", value: "lung-cancer" }, 
            { text: "Lung Cancer - Small Cell", value: "lung-cancer-small-cell" }, 
            { text: "Lymphoma - Hodgkin's", value: "lymphoma-hodgkins" }, 
            { text: "Lymphoma - Non-Hodgkin's", value: "lymphoma-non-hodgkins" }, 
            { text: "Malignant Glioma", value: "malignant-glioma" }, 
            { text: "Melanoma", value: "melanoma" }, 
            { text: "Meningioma", value: "meningioma" }, 
            { text: "Multiple Myeloma", value: "multiple-myeloma" }, 
            { text: "Myelodysplastic Syndrome (MDS)", value: "myelodysplastic-syndrome" }, 
            { text: "Nasopharyngeal Cancer", value: "nasopharyngeal-cancer" }, 
            { text: "Neuroendocrine Tumor", value: "neuroendocrine-tumor" }, 
            { text: "Oral Cancer", value: "oral-cancer" }, 
            { text: "Osteosarcoma", value: "osteosarcoma" }, 
            { text: "Ovarian Cancer", value: "ovarian-cancer" }, 
            { text: "Pancreatic Cancer", value: "pancreatic-cancer" }, 
            { text: "Pancreatic Neuroendocrine Tumors", value: "pancreatic-neuroendocrine-tumors" }, 
            { text: "Parathyroid Cancer", value: "parathyroid-cancer" }, 
            { text: "Penile Cancer", value: "penile-cancer" }, 
            { text: "Peritoneal Cancer", value: "peritoneal-cancer" }, 
            { text: "Peutz-Jeghers Syndrome", value: "peutz-jeghers-syndrome" }, 
            { text: "Pituitary Gland Tumor", value: "pituitary-gland-tumor" }, 
            { text: "Polycythemia Vera", value: "polycythemia-vera" }, 
            { text: "Prostate Cancer", value: "prostate-cancer" }, 
            { text: "Renal Cell Carcinoma", value: "renal-cell-carcinoma" }, 
            { text: "Retinoblastoma", value: "retinoblastoma" }, 
            { text: "Salivary Gland Cancer", value: "salivary-gland-cancer" }, 
            { text: "Sarcoma", value: "sarcoma" }, 
            { text: "Skin Cancer", value: "skin-cancer" }, 
            { text: "Small Intestine Cancer", value: "small-intestine-cancer" }, 
            { text: "Stomach Cancer", value: "stomach-cancer" }, 
            { text: "Testicular Cancer", value: "testicular-cancer" }, 
            { text: "Thymoma", value: "thymoma" }, 
            { text: "Thyroid Cancer", value: "thyroid-cancer" }, 
            { text: "Uterine (Endometrial) Cancer", value: "uterine-cancer" }, 
            { text: "Vaginal Cancer", value: "vaginal-cancer" }, 
            { text: "Wilms' Tumor", value: "wilms-tumor" }, 
            // { text: "A type of cancer which is not in this list", value: "not-in-list" }
        ],
        med_cardiovascular: [
            { text: "Abnormal heart rhythms, or arrhythmias", value: "arrhythmias" }, 
            { text: "Aorta disease", value: "aorta-disease" }, 
            { text: "Coronary artery disease", value: "coronary-artery-disease" }, 
            { text: "Congenital heart disease", value: "congenital-heart-disease" }, 
            { text: "Deep vein thrombosis and pulmonary embolism", value: "deep-vein-thrombosis-and-pulmonary-embolism" }, 
            { text: "Familial hypercholesterolaemia", value: "familial-hypercholesterolaemia" }, 
            { text: "Heart attack", value: "heart-attack" }, 
            { text: "Heart failure", value: "heart-failure" }, 
            { text: "Heart muscle disease (cardiomyopathy)", value: "cardiomyopathy" }, 
            { text: "Heart valve disease", value: "heart-valve-disease" }, 
            { text: "Marfan syndrome", value: "marfan-syndrome" }, 
            { text: "Pericardial disease", value: "pericardial-disease" }, 
            { text: "Peripheral vascular disease", value: "peripheral-vascular-disease" }, 
            { text: "Rheumatic heart disease", value: "rheumatic-heart-disease" }, 
            { text: "Stroke", value: "stroke" }, 
            { text: "Vascular disease", value: "vascular-disease" }, 
            // { text: "A cardiovascular condition which is not in this list", value: "not-in-list" }
        ],
        med_digestive: [
            { text: "Barrett's oesophagus", value: "barretts-oesophagus" }, 
            { text: "Bowel obstruction", value: "bowel-obstruction" }, 
            { text: "Celiac disease", value: "celiac-disease" }, 
            { text: "Crohn\u2019s disease", value: "crohns-disease" }, 
            { text: "Diverticulitis", value: "diverticulitis" }, 
            { text: "Gallstones", value: "gallstones" }, 
            { text: "Gastric ulcers", value: "gastric-ulcers" }, 
            { text: "Gastritis", value: "gastritis" }, 
            { text: "Gastroesophageal Reflux Disease (GERD)", value: "gastroesophageal-reflux-disease" }, 
            { text: "Hemorrhoids", value: "hemorrhoids" }, 
            { text: "Irritable bowel syndrome", value: "irritable-bowel-syndrome" }, 
            { text: "Necrotising enterocolitis", value: "necrotising-enterocolitis" }, 
            { text: "Oesophagitis", value: "oesophagitis" }, 
            { text: "Pernicious anemia", value: "pernicious-anemia" }, 
            { text: "Pyloric stenosis", value: "pyloric-stenosis" }, 
            { text: "Ulcerative colitis", value: "ulcerative-colitis" }, 
            // { text: "A digestive condition which is not in this list", value: "not-in-list" }
        ],
        med_metabolic_endocrine: [
            { text: "Type 1 diabetes", value: "type-1-diabetes" }, 
            { text: "Type 2 diabetes", value: "type-2-diabetes" }, 
            { text: "Gaucher\u2019s disease", value: "gauchers-disease" }, 
            { text: "Glucose galactose malabsorption", value: "glucose-galactose-malabsorption" }, 
            { text: "Hereditary hemochromatosis", value: "hereditary-hemochromatosis" }, 
            { text: "Maple syrup urine disease", value: "maple-syrup-urine-disease" }, 
            { text: "Phenylketonuria (PKU)", value: "phenylketonuria" }, 
            { text: "Medium-chain acyl-CoA dehydrogenase deficiency (MCADD)", value: "medium-chain-acyl-coa-dehydrogenase-deficiency" }, 
            { text: "Glycogen storage disease", value: "glycogen-storage-disease" }, 
            { text: "Galactosemia", value: "galactosemia" }, 
            { text: "Congenital disorders of glycosylation (CDG)", value: "congenital-disorders-of-glycosylation" }, 
            { text: "Lysosomal storage disorders (LSD)", value: "lysosomal-storage-disorders" }, 
            { text: "Organic acidemias", value: "organic-acidemias" }, 
            { text: "Mitochondrial disorders", value: "mitochondrial-disorders" }, 
            // { text: "An endocrine, nutritional or metabolic condition which is not in this list", value: "not-in-list" }
        ],
        med_immune: [
            { text: "Ankylosing Spondylitis", value: "ankylosing-spondylitis" }, 
            { text: "Celiac disease", value: "celiac-disease" }, 
            { text: "Chronic inflammatory demyelinating polyneuropathy", value: "chronic-inflammatory-demyelinating-polyneuropathy" }, 
            { text: "Graves' disease", value: "graves-disease" }, 
            { text: "Guillain-Barre syndrome", value: "guillain-barre-syndrome" }, 
            { text: "Hashimoto's thyroiditis", value: "hashimotos-thyroiditis" }, 
            { text: "Inflammatory bowel disease (IBD)", value: "inflammatory-bowel-disease" }, 
            { text: "Multiple sclerosis (MS)", value: "multiple-sclerosis" }, 
            { text: "Myasthenia gravis", value: "myasthenia-gravis" }, 
            { text: "Psoriasis", value: "psoriasis" }, 
            { text: "Rheumatoid arthritis", value: "rheumatoid-arthritis" }, 
            { text: "Sj\u00f6gren\u2019s syndrome", value: "sjogrens-syndrome" }, 
            { text: "Systemic lupus erythematosus (Lupus)", value: "systemic-lupus-erythematosus" }, 
            { text: "Type 1 diabetes mellitus", value: "type-1-diabetes-mellitus" }, 
            { text: "Vasculitis", value: "vasculitis" }, 
            // { text: "An immune condition which is not in this list", value: "not-in-list" }
        ],
        med_liver_pancreas_spleen_kidney: [
            { text: "Acute kidney failure", value: "acute-kidney-failure" }, 
            { text: "Acute pancreatitis", value: "acute-pancreatitis" }, 
            { text: "Alcoholic liver disease", value: "alcoholic-liver-disease" }, 
            { text: "Autosomal dominant polycystic kidney disease", value: "autosomal-dominant-polycystic-kidney-disease" }, 
            { text: "Autosomal dominant tubulointerstitial disease", value: "autosomal-dominant-tubulointerstitial-disease" }, 
            { text: "Cholangitis", value: "cholangitis" }, 
            { text: "Cholecystolithiasis", value: "cholecystolithiasis" }, 
            { text: "Chronic kidney disease", value: "chronic-kidney-disease" }, 
            { text: "Chronic pancreatitis", value: "chronic-pancreatitis" }, 
            { text: "Cirrhosis", value: "cirrhosis" }, 
            { text: "Cystitis", value: "cystitis" }, 
            { text: "Fatty liver disease", value: "fatty-liver-disease" }, 
            { text: "Hepatic fibrosis", value: "hepatic-fibrosis" }, 
            { text: "Hepatitis", value: "hepatitis" }, 
            { text: "Liver abscesses", value: "liver-abscesses" }, 
            { text: "NAFLD or NASH", value: "nafld-or-nash" }, 
            { text: "Nephritic syndrome", value: "nephritic-syndrome" }, 
            { text: "Nephritis", value: "nephritis" }, 
            { text: "Nephrocalcinosis", value: "nephrocalcinosis" }, 
            { text: "Nephronophthisis", value: "nephronophthisis" }, 
            { text: "Nephrotic syndrome", value: "nephrotic-syndrome" }, 
            { text: "Nonfamilial nongenetic cystic kidney disease", value: "nonfamilial-nongenetic-cystic-kidney-disease" }, 
            { text: "Obstructive or reflux nephropathy", value: "obstructive-or-reflux-nephropathy" }, 
            { text: "Persistent proteinuria or albuminuria", value: "persistent-proteinuria-or-albuminuria" }, 
            { text: "Pyonephrosis", value: "pyonephrosis" }, 
            { text: "Recurrent urinary tract infections", value: "recurrent-urinary-tract-infections" }, 
            { text: "Spleen fibrosis", value: "spleen-fibrosis" }, 
            { text: "Spleen infarction", value: "spleen-infarction" }, 
            { text: "Spleen laceration or rupture", value: "spleen-laceration-or-rupture" }, 
            { text: "Splenic cyst", value: "splenic-cyst" }, 
            { text: "Splenomegaly", value: "splenomegaly" }, 
            { text: "Splenosis", value: "splenosis" }, 
            { text: "Urethritis", value: "urethritis" }, 
            { text: "Urolithiasis", value: "urolithiasis" }, 
            // { text: "A liver, pancreas, spleen or kidney condition which is not in this list", value: "not-in-list" }
        ],
        med_mh: [
            { text: "Anxiety and panic attacks", value: "anxiety-and-panic-attacks" }, 
            { text: "Bipolar disorder", value: "bipolar-disorder" }, 
            { text: "Body dysmorphic disorder (BDD)", value: "body-dysmorphic-disorder" }, 
            { text: "Borderline personality disorder (BPD)", value: "borderline-personality-disorder" }, 
            { text: "Depression", value: "depression" }, 
            { text: "Eating disorders", value: "eating-disorders" }, 
            { text: "Obsessive-compulsive disorder (OCD)", value: "obsessive-compulsive-disorder" }, 
            { text: "Panic attacks", value: "panic-attacks" }, 
            { text: "Paranoia", value: "paranoia" }, 
            { text: "Personality disorders", value: "personality-disorders" }, 
            { text: "Phobias", value: "phobias" }, 
            { text: "Post-traumatic stress disorder (PTSD)", value: "post-traumatic-stress-disorder" }, 
            { text: "Premenstrual dysphoric disorder (PMDD)", value: "premenstrual-dysphoric-disorder" }, 
            { text: "Psychosis", value: "psychosis" }, 
            { text: "Schizoaffective disorder", value: "schizoaffective-disorder" }, 
            { text: "Schizophrenia", value: "schizophrenia" }, 
            { text: "Seasonal affective disorder (SAD)", value: "seasonal-affective-disorder" }, 
            { text: "Self-harm", value: "self-harm" }, 
            { text: "Sleep problems", value: "sleep-problems" }, 
            { text: "Stress", value: "stress" }, 
            { text: "Tardive dyskinesia", value: "tardive-dyskinesia" }, 
            // { text: "A mental health condition which is not in this list", value: "not-in-list" }
        ],
        med_neurological: [
            { text: "Amyotrophic lateral sclerosis (ALS)", value: "amyotrophic-lateral-sclerosis" }, 
            { text: "Alzheimer\u2019s disease", value: "alzheimers-disease" }, 
            { text: "Aneurysm", value: "aneurysm" }, 
            { text: "Ataxia", value: "ataxia" }, 
            { text: "Bell\u2019s palsy", value: "bells-palsy" }, 
            { text: "Brain injury", value: "brain-injury" }, 
            { text: "Cerebral palsy", value: "cerebral-palsy" }, 
            { text: "Chronic fatigue syndrome", value: "chronic-fatigue-syndrome" }, 
            { text: "Dementia", value: "dementia" }, 
            { text: "Dizziness", value: "dizziness" }, 
            { text: "Dystonia", value: "dystonia" }, 
            { text: "Epilepsy", value: "epilepsy" }, 
            { text: "Guillain-Barr\u00e9 syndrome", value: "guillain-barre-syndrome" }, 
            { text: "Headaches", value: "headaches" }, 
            { text: "Lewy body disease", value: "lewy-body-disease" }, 
            { text: "Migraines", value: "migraines" }, 
            { text: "Multiple sclerosis", value: "multiple-sclerosis" }, 
            { text: "Muscular dystrophy", value: "muscular-dystrophy" }, 
            { text: "Neuralgia", value: "neuralgia" }, 
            { text: "Neuropathy", value: "neuropathy" }, 
            { text: "Parkinson\u2019s disease", value: "parkinsons-disease" }, 
            { text: "Seizures", value: "seizures" }, 
            { text: "Spinal cord injury", value: "spinal-cord-injury" }, 
            { text: "Spinal deformity", value: "spinal-deformity" }, 
            { text: "Stroke", value: "stroke" }, 
            { text: "Tic disorders", value: "tic-disorders" }, 
            { text: "Vertigo", value: "vertigo" }, 
            // { text: "A nervous system or brain condition which is not in this list", value: "not-in-list" }
        ],
        med_pregnancy_complications: [
            { text: "Eclampsia", value: "eclampsia" },
            { text: "Ectopic pregnancy", value: "ectopic-pregnancy" }, 
            { text: "Gestational Diabetes", value: "gestational-diabetes" }, 
            {  text: "Hyperemesis gravidarum", value: "hyperemesis-gravidum" }, 
            { text: "Placental abruption", value: "placental-abruption" }, 
            { text: "Placenta previa", value: "placenta-previa" }, 
            { text: "Postnatal depression", value: "postnatal-depression" }, 
            { text: "Preeclampsia", value: "preeclampsia" }, 
            { text: "Pregnancy related anemia", value: "pregnancy-related-anemia" }, 
            { text: "Prenatal depression", value: "prenatal-depression" }, 
            { text: "Preterm labour", value: "preterm-labour" }, 
            { text: "Recurrent miscarriage", value: "recurrent-miscarriage" }, 
            { text: "Urinary tract infection", value: "urinary-tract-infection" }, 
            // { text: "A pregnancy complication which is not in this list", value: "not-in-list" }
        ],
        med_rare: [],
        med_respiratory: [
            { text: "Acute bronchitis", value: "acute-bronchitis" }, 
            { text: "Acute respiratory distress syndrome (ARDS)", value: "acute-respiratory-distress-syndrome" }, 
            { text: "Asthma", value: "asthma" }, 
            { text: "Chronic obstructive pulmonary disease (COPD)", value: "chronic-obstructive-pulmonary-disease" }, 
            { text: "Chronic bronchitis", value: "chronic-bronchitis" }, 
            { text: "Cystic fibrosis", value: "cystic-fibrosis" }, 
            { text: "Emphysema", value: "emphysema" }, 
            { text: "Interstitial lung disease (ILD)", value: "interstitial-lung-disease" }, 
            { text: "Pleural effusion", value: "pleural-effusion" }, 
            { text: "Pulmonary edema", value: "pulmonary-edema" }, 
            { text: "Pulmonary embolism (PE)", value: "pulmonary-embolism" }, 
            { text: "Pulmonary hypertension", value: "pulmonary-hypertension" }, 
            { text: "Pneumoconiosis", value: "pneumoconiosis" }, 
            { text: "Pneumonias", value: "pneumonias" }, 
            { text: "Pneumothorax", value: "pneumothorax" }, 
            { text: "Tuberculosis", value: "tuberculosis" }, 
            // { text: "A lung or respiratory condition which is not in this list", value: "not-in-list" }
        ],
        med_sense: [
            { text: "Blindness/Visual Impairment", value: "blindness-visual-impairment" }, 
            { text: "Cataracts", value: "cataracts" }, 
            { text: "Deafness", value: "deafness" }, 
            { text: "Glaucoma", value: "glaucoma" }, 
            { text: "Microphthalmia", value: "microphthalmia" }, 
            { text: "Nystagmus", value: "nystagmus" }, 
            { text: "Ptosis", value: "ptosis" }, 
            { text: "Sensory Processing Disorder", value: "sensory-processing-disorder" },
            { text: "Strabismus", value: "strabismus" }, 
            // { text: "A sense affecting condition which is not in this list", value: "not-in-list" }
        ],
        med_skin: [
            { text: "Acne", value: "acne" }, 
            { text: "Athlete\u2019s foot", value: "athletes-foot" }, 
            { text: "Contact dermatitis", value: "contact-dermatitis" }, 
            { text: "Eczema", value: "eczema" }, 
            { text: "Herpes Zoster", value: "herpes-zoster" }, 
            { text: "Impetigo", value: "impetigo" }, 
            { text: "Keratosis", value: "keratosis" }, 
            { text: "Psoriasis", value: "psoriasis" }, 
            { text: "Rosacea", value: "rosacea" }, 
            { text: "Urticaria", value: "urticaria" }, 
            { text: "Vitiligo", value: "vitiligo" }, 
            { text: "Warts", value: "warts" }, 
            // { text: "A skin condition which is not in this list", value: "not-in-list" }
        ],
        med_srh: [
            { text: "Dysmenorrhea", value: "dysmenorrhea" },
            { text: "Endometriosis", value: "endometriosis" }, 
            { text: "Hypogonadism", value: "hypogonadism" }, 
            { text: "Impotence", value: "impotence" }, 
            { text: "Interstitial Cystitis", value: "interstitial-cystitis" }, 
            { text: "Male factor infertility", value: "male-factor-infertility" }, 
            { text: "Polycystic Ovary Syndrome (PCOS)", value: "polycystic-ovary-syndrome" }, 
            { text: "Premature ejaculation", value: "premature-ejaculation" }, 
            { text: "Primary infertility", value: "primary-infertility" }, 
            { text: "Reproductive tract infections", value: "reproductive-tract-infections" }, 
            { text: "Secondary infertility", value: "secondary-infertility" }, 
            { text: "Uterine Fibroids", value: "uterine-fibroids" }, 
            // { text: "A sexual or reproductive health condition which is not in this list", value: "not-in-list" }
        ]
    }
};

export default aQs;
