<template>
    <div>
        <div class="portal-input rounded-md">
            <label :for="question.id" class="sr-only">{{ question.title }}</label>
            <input
                v-if="question.noBox == null"
                :id="question.id"
                v-model.trim="textInput"
                :data-cy="`${qName}-input`"
                class="input w-full rounded-md appearance-none px-3 py-2 border border-mid-grey high-c-border-black bg-white"
                type="text"
                :name="qName"
                @input="onInput"
                @keyup.enter="nextClick"
            />
            <div class="focus-border"></div>
        </div>
        <div class="text-portal-sm mb-2">
            <div class="mt-1 sm:mt-3" :class="{ 'opacity-0': !showError }">
                <div
                    class="bg-warn text-over-warn rounded-lg flex items-center justify-center text-sm leading-none text-lighter"
                    style="min-height: 2rem;"
                >
                    <div v-for="error of v$.textInput.$errors" :key="`error-${error.$uid}`" class="px-4">
                        <span v-if="error.$validator === 'required' && isRequired" :data-cy="`${qName}-missing`">{{ strings.required }}</span>
                        <span v-else-if="error.$validator === 'noElementTags'" :data-cy="`${qName}-includes-tags`">
                            {{ strings.error }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import debounce from "lodash.debounce";
import text from "./surveyTextboxText";

const noElementTags = (input) => {
    return !input.includes("<") && !input.includes(">");
};

export default {
    name: "SurveyTextbox",
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: String,
    },
    setup() {
        // https://vuelidate-next.netlify.app/#getting-started-2
        return { v$: useVuelidate() };
    },
    data() {
        return {
            textInput: null,
            strings: text,
        };
    },
    computed: {
        isRequired() {
            return this.question.isRequired;
        },
        qName() {
            return this.question.name;
        },
        existingInput() {
            return this.question.userAnswer;
        },
        showError() {
            // If the only error is 'required', and this answer is not required, emit no error
            const errors = this.v$.textInput.$errors;
            const requiredErrorsLength = errors.filter((e) => e.$validator === "required").length;
            const otherErrorsLength = errors.length - requiredErrorsLength;
            return otherErrorsLength > 0 || (!!this.isRequired && requiredErrorsLength > 0);
        },
    },
    validations: {
        textInput: { required, noElementTags },
    },
    created() {
        if (this.existingInput) {
            this.textInput = this.existingInput;
        }
    },
    methods: {
        onInput() {
            // Make sure that any errors show up
            if (!this.v$.textInput.$dirty) this.v$.textInput.$touch();
            this.handleInputAfterDebounce(this);
        },
        handleInputAfterDebounce: debounce((vm) => {
            console.log(vm.showError);

            // And that current status of errors (whether are some or no) gets emmitted up
            vm.$emit("textbox-error", vm.showError);

            // If there are no problems, also emit the input up to the parent
            if (!vm.showError) {
                vm.$emit("update", {
                    surveyId: vm.surveyId,
                    qName: vm.qName,
                    val: vm.textInput,
                });
            }
        }, 200),
        nextClick() {
            this.$emit("next-click");
        },
    },
};
</script>
