<template>
    <div class="w-full max-w-2xl px-4">
        <!-- <div class="relative bg-white z-20">{{ answers }}</div> -->
        <div v-if="!loading" :class="{ 'opacity-0': !fadeUp }" class="trans-opacity w-full">
            <p class="sr-only" aria-live="polite" role="region">
                {{ strings.page.replace("{number}", currentPageDisplayNumber).replace("{total}", currentlyVisiblePages.filter((p) => !!p).length) }}
            </p>
            <Carousel
                v-swipe="okToSwipe"
                class="relative z-10 my-24"
                :totalSlides="totalPages + 1"
                name="surveyslider"
                noInfiniteRotate
                showPrevNext
                fixedPrevNext
                allowDifferentHeights
                emitPrevNextRequests
                :parentDisallowsNext="!currentPageIsValid"
                :updateCurrentSlideTo="currentPage"
                @prev-next-request="findNextVisiblePage"
            >
                <template v-for="(page, p_index) in pagesWithAnswers" v-slot:[`slot-${p_index}`]="pagesWithAnswers" :key="`survey-page-${p_index}`">
                    <div style="min-height: inherit">
                        <!-- Slides are 'boxed' (white background, padding etc.) unless specified  -->
                        <div class="max-w-lg mx-auto w-full" :class="{ 'px-5 py-6': !page.unboxed }">
                            <div class="w-full">
                                <div
                                    v-for="(question, qIndex) in Object.values(page.elements)"
                                    :key="`qIndex-${question.name}`"
                                    :data-cy="question.name"
                                    class="question w-full"
                                    :class="{ 'max-w-md mx-auto md:px-8': page.width === 'narrower' || !!narrowerPages[p_index] }"
                                    :style="`z-index: ${question.type === 'dropdown' || question.type == 'consent' ? 20 - qIndex : 10};`"
                                >
                                    <div v-if="question.title || question.subtitle" class="leading-tight mx-auto pb-2 sm:pb-4 lg:pb-6">
                                        <h2 v-if="question.title" class="text-primary high-c-text-black display-text text-xl xs:text-2xl lg:text-3xl">
                                            <span class="block leading-tight xs:leading-snug">{{ question.title }}</span>
                                        </h2>
                                        <p v-if="question.subtitle" class="pt-2 text-base text-lighter">
                                            <span class="block leading-snug">{{ question.subtitle }}</span>
                                        </p>
                                    </div>
                                    <!-- Text inputs -->
                                    <SurveyTextbox
                                        v-if="question.type === 'text'"
                                        :question="question"
                                        :surveyId="page.surveyId"
                                        @update="changeAnswerValue"
                                        @textbox-error="componentErrorChange"
                                        @next-click="findNextVisiblePage(1)"
                                    />
                                    <!-- Phone numbers -->
                                    <PhoneNumberTextbox
                                        v-else-if="question.type === 'phone'"
                                        :question="question"
                                        :surveyId="page.surveyId"
                                        @update="changeAnswerValue"
                                        @phonefield-error="componentErrorChange"
                                    />
                                    <!-- Multiple choice: In older surveys, we use radiogroup, radiogroup-box, checkbox & checkbox-cards -->
                                    <!-- To make the builder more user friendly, we now put all of these under 'multiple-choice', with settings to make the differences -->
                                    <!-- Radio group / Radio group box -->
                                    <Radiobuttons
                                        v-else-if="question.type === 'multiple-choice' && !question.selectMulti"
                                        :question="question"
                                        :surveyId="page.surveyId"
                                        @update="changeAnswerValue"
                                    />
                                    <!-- Special type: Medical history categories -->
                                    <MedHistCategories
                                        v-else-if="question.name === 'med_categories'"
                                        :question="question"
                                        :answers="answers.useraccount"
                                        :surveyId="page.surveyId"
                                        @medical-history-sync-error="medicalHistorySyncError = true"
                                        @update="changeAnswerValue"
                                    />
                                    <Checkboxes
                                        v-else-if="question.type === 'multiple-choice' && question.selectMulti"
                                        :question="question"
                                        :surveyId="page.surveyId"
                                        @update="changeAnswerValue"
                                    />
                                    <!-- Date input (currently without popup datepicker) -->
                                    <DatePicker
                                        v-else-if="question.type === 'datepicker'"
                                        :question="question"
                                        :surveyId="page.surveyId"
                                        @datepicker-error="componentErrorChange"
                                        @update="changeAnswerValue"
                                    />
                                    <!-- Dropdown / select component -->
                                    <SurveySelect
                                        class="pb-4 pt-1"
                                        v-else-if="question.type === 'dropdown'"
                                        :question="question"
                                        :surveyId="page.surveyId"
                                        @update="changeAnswerValue"
                                    />
                                    <div v-else>{{ question }}</div>
                                    <p v-if="question.explanation" class="text-base leading-snug pt-3 text-lighter">
                                        {{ question.explanation }}
                                    </p>
                                </div>
                            </div>
                            <!-- page error messages / hints -->
                            <div
                                v-if="currentPage < totalPages"
                                :class="{ 'opacity-0': !medicalHistorySyncError && (currentPageIsValid || !touchedNext) }"
                                class="w-full trans-opacity text-lighter absolute bottom-0 left-0"
                                :aria-hidden="!medicalHistorySyncError && (currentPageIsValid || !touchedNext)"
                                aria-live="polite"
                            >
                                <div
                                    class="
                                        relative
                                        bg-warn
                                        text-over-warn
                                        flex
                                        items-center
                                        justify-center
                                        leading-tight
                                        text-center text-portal-sm
                                        max-w-lg
                                        mx-auto
                                        rounded-full
                                    "
                                    style="min-height: 2rem; transform: translateY(150%)"
                                >
                                    <!-- Invalid page (incomplete required questions) -->
                                    <span v-if="incompleteQsByPage[currentPage].length > 0" data-cy="qs-incomplete">
                                        {{ strings.incomplete }}
                                    </span>
                                    <!-- There's an issue with a component on the page - e.g. "<" entered into textbox -->
                                    <span v-else-if="componentError" data-cy="problem-with-q-on-page">
                                        {{ strings.problem }}
                                    </span>
                                    <!-- There an issue with the medical history <> diagnosis sync -->
                                    <span v-else-if="medicalHistorySyncError" data-cy="med-hist-sync-error">
                                        {{ strings.diagnoses }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- Thanks page -->
                <template v-slot:[`slot-${totalPages}`] key="thanks_page">
                    <div class="flex w-full px-4 py-8 sm:px-8 sm:py-12 max-w-lg mx-auto" style="min-height: inherit">
                        <ThanksPage :isCurrentSlide="currentPage === totalPages" :user="user" @submit-completions="submitCompletions" />
                    </div>
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>
// components
import Carousel from "@/components/ui/carousel";
// survey components
import SurveyTextbox from "./surveyTextbox";
import PhoneNumberTextbox from "./phoneNumberTextbox";
import Radiobuttons from "./radiobuttons";
import Checkboxes from "./checkboxes";
import MedHistCategories from "./medHistCategories";
import DatePicker from "./datePicker";
import SurveySelect from "./surveySelect";
import ThanksPage from "./thanksPage";
// utility js files and models
import accountQuestions from "@/../../shared/js/accountQuestions";
import accountSettingsFunctions from "./accountSettingsFunctions";
import visibleIf from "./visibleIf";
import debounce from "lodash.debounce";
import { User } from "@/data/models";
import SliderText from "./surveySliderText";
import { swipe } from "./Swipe";

export default {
    name: "SurveySlider",
    components: {
        Carousel,
        SurveyTextbox,
        PhoneNumberTextbox,
        Radiobuttons,
        Checkboxes,
        MedHistCategories,
        DatePicker,
        SurveySelect,
        ThanksPage,
    },
    directives: {
        swipe,
    },
    props: {
        // startingPage: {
        //     type: Number,
        //     default: 0,
        // },
        completionUrl: {
            type: String,
            required: true,
        },
        fetchedSurvey: {
            type: [Object, null],
            default: () => {
                return null;
            },
        },
        fetchedSurveyResponse: {
            type: [Object, null],
            default: () => {
                return null;
            },
        },
        includeAccountQs: Boolean,
        specificAccountSlides: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            loading: true,
            fadeUp: false,
            currentPage: 0,
            answers: {},
            // Are there any errors in child components which we need to be aware of for validation
            componentError: null,
            touchedNext: false,
            medicalHistorySyncError: false,
            strings: SliderText,
            // swipe.js allows a user to swipe left/right to go back/forward between screens, sometimes we need to disable this functionality like when drawing in a signature box
            okToSwipe: true, // might need to set this value later e.g from an consent component
        };
    },
    async created() {
        // The survey, survey response & user object should have been fetched on the page which includes the survey slider
        // this is because we need the survey information to determine whether the user should be able to see & fill in this survey
        // Here though, we do set any existing answers
        const diagnosisQsIncluded =
            this.includeAccountQs &&
            !this.portalConfig.hide_medical_history &&
            (this.specificAccountSlides.length === 0 || this.specificAccountSlides.includes("medical"));

        if (this.includeAccountQs) {
            // Check if we're going to be asking about medical history diagnoses (in which case we need to fetch extra info)
            if (diagnosisQsIncluded) await Promise.all([this.$api.user.read_my_diagnoses(), this.$api.user.read_my_diagnosed_conditions()]);
            // Either way, we need to set existing user answers if we're including account questions in this slider
            this.answers.useraccount = accountSettingsFunctions.getExistingAnswers();
            console.log(this.answers);
        }

        // We'll also want to set any existing answers that we have from a survey response
        if (this.fetchedSurveyResponse?.survey_id) {
            this.answers[this.fetchedSurveyResponse.survey_id] = this.fetchedSurveyResponse.data;
        }

        this.loading = false;

        // Lastly (after load) we'll need to check that the page we have landed on should not be invisible. This is an edge case,
        // but can happen when a user wants to update only their ethnicity. If they are based in the US, the first page
        // in that page set should be hidden, but we need to trigger 'findNextVisiblePage' to skip it.
        this.$nextTick(() => {
            if (!this.currentlyVisiblePages[this.currentPage]) {
                this.findNextVisiblePage(1);
            }

            setTimeout(() => {
                this.fadeUp = true;
            }, 900);
        });
    },
    computed: {
        completionParams() {
            return this.$route.query.scrollTo ? { scrollTo: this.$route.query.scrollTo } : {};
        },
        user() {
            return User.query().first();
        },
        accountInfoPages() {
            if (!this.includeAccountQs) return [];
            // Otherwise, fetch the slides that we want to show for this instance
            const accountPages = [];
            let slidesToFetch = accountQuestions.coreOrder();
            const medIndex = slidesToFetch.indexOf("medical");
            // Account for the temporary config property - hide_medical_history questions
            if (medIndex > -1 && this.portalConfig.hide_medical_history) {
                slidesToFetch.splice(medIndex, 1);
            }
            // There may be only specific slides requested
            if (this.specificAccountSlides.length > 0) slidesToFetch = this.specificAccountSlides;

            slidesToFetch.map((qName) => {
                // For each question name in the specified order, fetch the survey pages needed from 'accountQuestions'
                // This is hacky & extremely temporary for the GE demo - we will soon be deleting the whole 'portal' folder
                const org_q_set =
                    process.env.VUE_APP_URL === "https://dev.sanogenetics.org" || process.env.VUE_APP_URL === "http://localhost:2000"
                        ? "GE-qs"
                        : "standard-qs";
                accountPages.push(...accountQuestions[qName](org_q_set));
                return;
            });

            return accountPages;
        },
        currentPageDisplayNumber() {
            console.log(this.currentPage, this.currentlyVisiblePages.slice(0, this.currentPage).filter((p) => !p).length);
            return this.currentPage - this.currentlyVisiblePages.slice(0, this.currentPage).filter((p) => !p).length;
        },
        pages() {
            // On the sano site, we used to account for multiple surveys in a single slider
            // this is not needed for the portal, so I have removed it to keep simplicity wherever possible
            let thisSurveysPages = [];

            if (this.fetchedSurvey) {
                thisSurveysPages = this.fetchedSurvey.definition.pages.map((page) => {
                    // It's useful to have the relevant surveyId available on each page
                    return { ...page, surveyId: this.fetchedSurvey.id };
                });
            }

            return [...this.accountInfoPages, ...thisSurveysPages];
        },
        pagesWithAnswers() {
            return this.pages.map((page) => {
                const newPage = { ...page };
                if (newPage.elements) {
                    newPage.elements.forEach((question) => {
                        // if there are answers for this question, add them to the question object
                        const existingAnswer = this.answers[newPage.surveyId]?.[question.name];
                        console.log(question.name, existingAnswer);
                        if (existingAnswer) question.userAnswer = existingAnswer;
                    });
                }
                return newPage;
            });
        },
        narrowerPages() {
            // This is just a visual thing, but some pages will look much better with the content being
            // narrower & centered (question + titles etc.)
            const narrowQTypes = ["datepicker", "multiple-choice", "phone", "dropdown", "text"];
            return this.pages.map((page) => {
                const widerQs = page.elements.filter((e) => !narrowQTypes.includes(e.type));
                const manyOptionMultiselects = page.elements.filter((e) => e.type === "multiple-choice" && e.choices.length > 7);

                // To check also, title, description lengths
                return widerQs.length === 0 && manyOptionMultiselects.length === 0;
            });
        },
        totalPages() {
            return this.pages.length;
        },
        // Single q visibleIfs are not currently used in this version of the surveyslider - it was decided that it was simplest to keep
        // (and nicer UX) to keep visibility conditions to whole pages and not to single questions within a page
        currentlyVisiblePages() {
            return this.pages.map((page) => {
                // console.log(page.name, page.visibleIf, page?.visibleIf?.length);
                if (!page.visibleIf || page.visibleIf.length === 0) {
                    console.log(page.name);
                    return true;
                }
                // If this page has visibility conditions
                else {
                    // Turn the visibleIf string into an object for easier handling
                    const conditions = this.parseVisibleif(page.visibleIf, page.surveyId);
                    // console.log(conditions);
                    // Loop through & test whether each condition is met. Map to an array of Booleans.
                    const conditionsMet = conditions.map((condition) => {
                        if (!condition.surveyId) console.error("Visibility condition missing surveyId");
                        const response = this.answers[condition.surveyId]?.[condition.dependantQ];
                        console.log(page.name, condition.surveyId, condition.dependantQ, response);
                        return visibleIf.testVisibleIf(response, condition);
                    });
                    // Return true if all conditions are met, or false if any are not
                    return conditionsMet.filter((c) => c !== true).length === 0;
                }
            });
        },
        incompleteQsByPage() {
            const iQbPs = this.pages.map((page) => {
                if (page.elements) {
                    const requiredEls = page.elements.filter((el) => {
                        // Accepting Strings, Arrays, or a specific object for the Easypost [contains 'postcode']
                        // & the consent/SNPchecker [contains 'overall'] question types
                        const answer = this.answers?.[page.surveyId]?.[el.name],
                            isValidAnswer =
                                !!answer &&
                                (answer.length > 0 ||
                                    (answer.postcode && answer.postcode.length > 0) ||
                                    (answer.overall && answer.overall.length > 0));
                        // console.log(el.name, el.isRequired, isValidAnswer);
                        return el.isRequired && !isValidAnswer;
                    });
                    return requiredEls.map((el) => el.name);
                }
                return [];
            });

            return iQbPs;
        },
        currentPageIsValid() {
            // console.log("check current page valid", this.incompleteQsByPage[this.currentPage].length);
            // The last page has no questions, only a complete button, and will never be invalid
            if (this.currentPage === this.totalPages) return true;
            if (this.componentError) return false;
            if (!this.incompleteQsByPage[this.currentPage]) return false;
            return this.incompleteQsByPage[this.currentPage].length === 0;
        },
    },
    methods: {
        parseVisibleif(visibilityString, surveyId) {
            // console.log(v_string, survey_id);
            return visibleIf.parseVisibleif({ visibilityString, surveyId });
        },
        componentErrorChange(value) {
            console.log("component err", value);
            this.componentError = value;
        },
        findNextVisiblePage(direction) {
            // Allow a forwards click only when the current page is valid (no errors, incomplete questions)
            console.log(this.componentError);
            if (this.currentPageIsValid || direction === -1) {
                // Reset any specific errors
                this.touchedNext = false;
                this.componentError = false;
                this.medicalHistorySyncError = false;

                // Longest possible necessary length of loop - total pages
                for (let i = 1; i <= this.totalPages; i += 1) {
                    const possibleNextPage = this.currentPage + i * direction,
                        isEndPage = possibleNextPage === this.totalPages;
                    // console.log(possibleNextPage);
                    // Check we're not hitting the end / beginning, Check visibility
                    console.log(possibleNextPage, this.totalPages);
                    if (isEndPage || (possibleNextPage < this.totalPages && possibleNextPage > -1 && this.currentlyVisiblePages[possibleNextPage])) {
                        this.currentPage = possibleNextPage;
                        return;
                    }
                }
                console.log("no page found");
            } else {
                console.log("show error");
                this.touchedNext = true;
            }
        },
        changeAnswerValue(inputObject) {
            console.log("input_object > ", inputObject);
            if (!inputObject.surveyId) console.error("Missing survey id");
            else {
                if (!this.answers[inputObject.surveyId]) this.answers[inputObject.surveyId] = {};
                this.answers[inputObject.surveyId][inputObject.qName] = inputObject.val;

                if (inputObject.surveyId === "useraccount") {
                    if (/^med_/.test(inputObject.qName) && inputObject.qName !== "med_categories") {
                        // Either set & remove diagnoses
                        accountSettingsFunctions.handleDiagnosisUpdate(this.answers.useraccount);
                    } else {
                        // Or set the relevant property on the user object
                        accountSettingsFunctions.handleUserUpdate(inputObject, this.answers.useraccount);
                    }
                } else {
                    // Or (standard surveys) submit the survey response
                    // (Diagnosis check answers should not be submitted. Diagnoses are set/unset within ConditionDiagnosisPage.)
                    if (inputObject.surveyId !== "check_diagnoses") {
                        const isCompletion = false;
                        this.submitSurveyResponse(inputObject.surveyId, this.currentPage, isCompletion, this);
                    }
                }
            }
        },
        submitSurveyResponse: debounce((surveyId, currentPage, isCompletion, vm) => {
            const thisData = vm.answers[surveyId];
            // Pulse surveys use a different method to record results, as those responses are never overwritten
            // if (isPulseSurvey) {
            //     return this.$api.user.create_pulse_response_for_survey(survey_id, this_data, this.consent_ask_each_time);
            // } else {
            // alwaysAskBeforeUsingData will not be used in portals, as this will always be set to true, but is currently still required by BE functions
            const alwaysAskBeforeUsingData = true,
                promise = vm.$api.user.update_my_response_for_survey(surveyId, thisData, isCompletion, alwaysAskBeforeUsingData);
            return promise;
            // }
        }, 50),
        async submitCompletions() {
            if (this.fetchedSurvey) {
                const surveyId = this.fetchedSurvey.id;
                const isCompletion = true;
                // Check that the survey does have question answers (Otherwise we get the occasional fail with an empty welcome survey)
                const surveyAs = this.answers[surveyId] ? Object.keys(this.answers[surveyId]) : [];

                if (surveyAs.length > 0) {
                    await this.submitSurveyResponse(surveyId, this.currentPage, isCompletion, this);
                    console.info("submitted responses ", surveyId);
                } else {
                    console.info("No response to submit for ", surveyId);
                }
            } else {
                console.info(`Survey does not exist`);
            }

            this.$router.push({ path: this.completionUrl, query: this.completionParams });
            // if (this.in_page_version || this.study_welcome_flow) {
            //     // In the study welcome flow, study-eligibility may be evaluated at the end of the surveys
            //     this.$emit("complete_change_answers");
            // } else {
            //     // if this survey is for a sano-platform study type and the survey is the prescreen, enroll the user in the study
            //     // Create a new ENROLLMENT for this user in this study
            //     if (completion_action === "complete_enrol") {
            //         await this.$api.user.create_enrollment_for_study(this.study_id_for_site_selector);
            //     }
            //     // Create a new REFERRAL for this user in this study
            //     else if (completion_action === "complete_refer") {
            //         await Event.create_my_referral_event(this.study_id_for_site_selector);
            //     }
            //     // Go to 'survey-completed' destination (If study is skipped, change destination)
            //     this.$router.push({ path: this.completion_url, query: this.completion_params });
            // }
        },
    },
};
</script>

<style scoped>
.no-visible-focus:focus::-moz-focus-inner {
    border: 0;
    -moz-outline-style: none;
    outline: none;
}

.question + .question {
    @apply mt-10;
}

@media (max-width: 768px) {
    .question + .question {
        @apply mt-4;
    }
}
</style>
